import * as React from "react"
import Layout from "../components/layout"
import CarbonPassHeader from "../components/carbonpassheader2"
import GetInTouch from "../components/getintouch"
import CloudOne from "../images/plane.svg"
import bgMobile2 from "../images/bg-mobile2.png"
import bgWave2 from "../images/bg-wave2.svg"
import forest from "../images/forest.jpg"
import nursey from "../images/nursery.jpg"
import fieldwork from "../images/natural.jpg"
import bgsky from "../images/bg-cloud.svg"


const ReadMore = () => (
  <Layout>
      <CarbonPassHeader/>
      <div className="relative">
      <div className="-mb-8 block pt-4 p-6 text-white bg-gradient-to-b from-carbonblue to-carbonbluelight">
          <img className='animate-[movingCloud_50s_linear_infinite] opacity-20' src={CloudOne} alt="Cloud" style={{height:"140px"}}></img>
         
          <div className="max-w-7xl mx-auto h-48">

          <h1 className="mt-10 text-5xl md:text-7xl font-bold text-white  mb-16 pt-4 lg:pt-8">
          About CarbonPass
          </h1>

          </div>
          <div className='animate-[movingCloudRight_150s_linear_infinite] bg-cover block' style={{backgroundImage: `url(${bgsky})`, width:"4000px",height:"200px"}}></div>
         
      </div>
      <div className="px-6 max-w-7xl mx-auto pt-16">

        <div className="grid sm:grid-cols-1  md:grid-cols-2">
            <div className="pb-12">
            <h2 className="mt-2 mb-6 md:mt-6 text-3xl leading-8 font-bold sm:text-4xl md:mb-10">What do you get with CarbonPass? </h2>
            <p className="text-xl">If you think CarbonPass could be a solution for your airport, here's  what you get in the package.</p>
            <br/>
            <ul className="text-left list-disc ml-6 text-xl">
                <li>Your airport added to the CarbonPass platform</li>
                <li>Destination database specific to your airport for passengers to select from </li>
                <li>You choose the project you want monies to go to and you decide the charging structure for your passengers (for example, you may want to charge an admin fee) - we will prepare your project specific pages that your passengers will access </li>
                <li>We will provide you template contracts between yourselves and your chosen project partner</li>
            </ul>
            </div>

            <div className="text-center pt-1.5">
            <div className="h-full -translate-y-10 px-6"  style={{backgroundImage: `url(${bgWave2})`, backgroundSize: 'cover',backgroundAttachment:'fixed'}}>

<img src={bgMobile2} className="w-5/6 mt-4" alt="mobile app screen"/>
</div>
                </div>
        </div>     
        </div>
        <div className="bg-gray-50">
        <div className="px-6 max-w-7xl mx-auto">
        <div className="grid sm:grid-cols-1  md:grid-cols-3 md:gap-8">
        <div className="md:inline hidden">
            
            <img src={forest} className="w-full" alt="Rain forest"/>
            </div>

            <div className="text-xl md:col-span-2 "><h2 className="mt-6 mb-6 md:mt-12 text-3xl leading-8 font-bold sm:text-4xl md:mb-8">What will it cost me?</h2>
            <p>There will be an initial design and deployment charge</p>
            <ul className="text-left list-disc ml-6">
                <li>Annual maintenance and support charge </li>
                <li>Additional charges should your airport wish to add bespoke content or functionality</li>
                <li>There are a number of charging structures which can be discussed dependent on airport size and requirements</li>
            </ul>

            
            <br/>
        
            </div> 
        </div> 
        </div>
        </div>
        <div>
        <div className="px-6 max-w-7xl mx-auto">

        <div className="grid sm:grid-cols-1  md:grid-cols-3 md:gap-8">
            <div className="md:col-span-2"> 
            <h2 className="mt-6 mb-6 md:mt-12 text-3xl leading-8 font-bold sm:text-4xl md:mb-8">What methodology do you use to calculate carbon emissions?</h2>
            <p className="text-xl">We use principles that consider aircraft type, passenger volumes, load factors and distance</p>
            <h2 className="mt-6 mb-6 md:mt-8 text-3xl leading-8 font-bold sm:text-4xl md:mb-8">Why is CarbonPass different to other carbon offsetting apps?</h2>
            <ul className="text-left text-xl list-disc ml-6 mb-16">
                <li>CarbonPass isn't about offsetting, it is about balancing. Projects don't have to be about purchasing carbon credits, they just need to demonstrate real tangible environmental benefits</li>
                <li>CarbonPass is designed to be specific to your airport, in terms of both your destinations and aircraft types</li>
                <li>You get to choose a project that means something to you, your passengers and local community</li>
            </ul>
            
            
            </div>

            <div className="md:inline hidden overflow-hidden">
            <img src={nursey} className="w-full " alt="Reforestation"/>
            </div>
            </div>
            </div>
            </div>
            <div>
            <div className="bg-gray-50">
        <div className="px-6 max-w-7xl mx-auto">
            <div className="grid sm:grid-cols-1  md:grid-cols-3 md:gap-8">
            <div className="">
            <img src={fieldwork} className="w-full pb-6" alt="fieldwork"/>
            </div>
            <div className="md:col-span-2 text-xl"> 
            <h2 className="mt-6 mb-6 md:mt-12 text-3xl leading-8 font-bold sm:text-4xl md:mb-6">Many airlines already offer offsetting options to passengers, why should we choose CarbonPass?</h2>
            <p className="mb-6">Some airlines also publicise they have offset passenger emissions in their total ticket price. This isn't always clear. </p>
            <p className="mb-6">The projects are often generic carbon offsetting schemes and they don't align with the airport's own sustainability partners.</p>
            <p className="mb-12">CarbonPass is a mechanism to educate passengers about the carbon footprint of their total travel experience and should they wish, to contribute to a project that hopefully as patrons of the host airport means something to them.</p>
            </div>
        </div> 
        </div>
        </div>
        </div>

        </div>
        <GetInTouch/>
  </Layout>
)

export default ReadMore
